<template>
  <div
    ref="container"
    class="multi-ring-chart"
  >
    <!-- SVG container for the multi-ring chart -->
    <svg
      ref="chart"
      :viewBox="`0 0 ${computedSize} ${computedSize}`"
      preserveAspectRatio="xMidYMid meet"
      aria-hidden="true"
    ></svg>
    <!-- Tooltip for displaying item information on hover/touch -->
    <div
      v-if="showTooltip"
      class="tooltip"
      :style="{ top: tooltipY + 'px', left: tooltipX + 'px' }"
      role="tooltip"
    >
      <p>{{ tooltipText }}</p>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import { debounce } from 'lodash'

export default {
  props: {
    chartData: {
      type: Array,
      required: true,
      validator: (value) =>
        value.length > 0 &&
        value.every(
          (item) =>
            typeof item.name === 'string' &&
            typeof item.value === 'number' &&
            typeof item.goal === 'number' &&
            typeof item.unit === 'string' &&
            typeof item.perc === 'number'
        ),
    },
    colors: {
      type: Array,
      default: () => ['#5ACF62', '#92BFDD', '#07133B', '#FBB03B', '#E57A44'],
    },
    animationDuration: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      showTooltip: false,
      tooltipText: '',
      tooltipX: 0,
      tooltipY: 0,
      calculatedSize: 200,
      minRingWidth: 10,
      centerHoleRatio: 0.22,
    }
  },
  computed: {
    // Calculate the size of the chart based on the number of items
    computedSize() {
      return this.calculatedSize + Math.max(0, this.chartData.length - 5) * 40
    },
  },
  watch: {
    // Redraw the chart when chartData changes
    chartData: {
      handler: 'drawChart',
      deep: true,
    },
  },
  mounted() {
    this.calculateSize()
    this.drawChart()
    // Set up debounced resize handler
    this.debouncedResize = debounce(this.onResize, 200)
    window.addEventListener('resize', this.debouncedResize)
    document.addEventListener('click', this.hideTooltipOnOutsideClick)
    document.addEventListener('touchstart', this.hideTooltipOnOutsideTouch)
  },
  beforeUnmount() {
    // Clean up event listeners
    window.removeEventListener('resize', this.debouncedResize)
    document.removeEventListener('click', this.hideTooltipOnOutsideClick)
    document.removeEventListener('touchstart', this.hideTooltipOnOutsideTouch)
    // Remove D3 event listeners
    d3.select(this.$refs.chart)
      .selectAll('*')
      .on('mouseover', null)
      .on('mouseout', null)
      .on('touchstart', null)
      .on('touchend', null)
  },
  methods: {
    // Generate description for accessibility
    generateDescription() {
      return this.chartData.map((item) => `${item.name}: ${item.perc * 100}%`).join('. ')
    },
    // Get color palette for rings
    getColorPalette() {
      return this.chartData.map((_, idx) => this.colors[idx % this.colors.length])
    },
    // Calculate the size of the chart based on container width
    calculateSize() {
      if (this.$refs.container) {
        const containerWidth = this.$refs.container.offsetWidth
        this.calculatedSize = Math.min(containerWidth, 200) // Using 200 as default max size
      }
    },
    // Handle resize events
    onResize() {
      this.calculateSize()
      this.drawChart()
    },
    // Draw a single ring
    drawRing(parent, arc, color, opacity, item) {
      const path = parent
        .append('path')
        .attr('d', this.createArc({ ...arc, end: 0 }))
        .attr('fill', color)
        .attr('opacity', opacity)

      // Animate the ring drawing
      path
        .transition()
        .duration(this.animationDuration)
        .ease(d3.easeQuadOut)
        .attrTween('d', () => {
          const interpolate = d3.interpolate(0, arc.end)
          return (t) => this.createArc({ ...arc, end: interpolate(t) })()
        })

      // Add event listeners for tooltip
      path
        .on('mousemove', (event) => this.showTooltipInfo(event, item))
        .on('mouseout', () => {
          this.showTooltip = false
        })

      // Use passive event listener for touchstart
      path.node().addEventListener(
        'touchstart',
        (event) => {
          this.handleTouch(event, item)
        },
        { passive: true }
      )

      return path
    },
    // Handle touch events
    handleTouch(event, item) {
      let touchTimeout = null

      const touchStart = event.touches[0]
      const startTime = new Date().getTime()

      const touchEnd = (endEvent) => {
        const endTime = new Date().getTime()

        if (endTime - startTime < 200) {
          // This was a tap, not a scroll
          this.showTooltipInfo(endEvent.changedTouches[0], item, true)
        }

        document.removeEventListener('touchend', touchEnd)
        clearTimeout(touchTimeout)
      }

      document.addEventListener('touchend', touchEnd)

      // Fallback in case touchend doesn't fire
      touchTimeout = setTimeout(() => {
        document.removeEventListener('touchend', touchEnd)
      }, 1000)
    },
    // Create an arc path for D3
    createArc(arc) {
      return d3
        .arc()
        .innerRadius(arc.inner)
        .outerRadius(arc.outer)
        .startAngle(arc.start)
        .endAngle(arc.end)
        .cornerRadius(60)
    },
    // Show tooltip with item information
    showTooltipInfo(event, item, isTouchEvent = false) {
      const windowWidth = window.innerWidth
      const windowHeight = window.innerHeight

      this.showTooltip = true
      this.tooltipText = `${item.name}: ${item.value}/${item.goal}${item.unit} (${(
        item.perc * 100
      ).toFixed(2)}%)`

      // Adjust tooltip size for smaller screens
      const tooltipWidth = windowWidth < 768 ? 150 : 200
      const tooltipHeight = windowWidth < 768 ? 50 : 60

      let tooltipX, tooltipY

      if (isTouchEvent) {
        // For touch events on mobile, position the tooltip at the bottom center of the screen
        tooltipX = (windowWidth - tooltipWidth) / 2
        tooltipY = windowHeight - tooltipHeight - 20
      } else {
        // For mouse events, position near the cursor but ensure it stays on screen
        tooltipX = Math.min(event.clientX, windowWidth - tooltipWidth - 10)
        tooltipY = Math.min(event.clientY, windowHeight - tooltipHeight - 10)
      }

      // Ensure the tooltip doesn't go off-screen
      tooltipX = Math.max(10, tooltipX)
      tooltipY = Math.max(10, tooltipY)

      this.tooltipX = tooltipX
      this.tooltipY = tooltipY

      // Add a small delay for touch events to differentiate from scrolling
      if (isTouchEvent) {
        setTimeout(() => {
          this.showTooltip = true
        }, 300)
      }
    },
    // Hide tooltip when clicking outside the chart
    hideTooltipOnOutsideClick(event) {
      if (!this.$refs.chart.contains(event.target)) {
        this.showTooltip = false
      }
    },
    // Hide tooltip when touching outside the chart on mobile
    hideTooltipOnOutsideTouch(event) {
      if (this.showTooltip && !this.$refs.chart.contains(event.target)) {
        this.showTooltip = false
      }
    },
    // Rings Texts
    addRingText(parent, item, arc) {
      // 90 degrees, top of the ring
      const angle = Math.PI / 2
      const radius = (arc.inner + arc.outer) / 2
      const x = Math.cos(angle) * radius
      // Adjust Y position to move text down
      const y = -Math.sin(angle) * radius + 1

      const fontSize = 6

      const textGroup = parent
        .append('g')
        .attr('transform', `translate(${x},${y})`)
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')

      textGroup
        .append('text')
        .attr('class', 'ring-text')
        .attr('font-size', `${fontSize}px`)
        .attr('fill', '#ffffff')
        .attr('font-weight', 'bold')
        .style('text-shadow', '0 0 2px rgba(0, 0, 0, 0.8)')
        .text(`${item.value} / ${item.goal}`)
    },
    // Draw the entire chart
    drawChart() {
      const chart = d3.select(this.$refs.chart)
      const radius = this.computedSize / 2 - 20
      const centerHoleRadius = radius * this.centerHoleRatio
      const availableSpace = radius - centerHoleRadius
      const ringWidth = Math.max(this.minRingWidth, availableSpace / (this.chartData.length + 1))
      const ringGap = Math.min(1, ringWidth / 5)
      const colors = this.getColorPalette()

      // Clear existing chart
      chart.selectAll('g').remove()

      // Create main group for rings
      const rings = chart
        .append('g')
        .attr('transform', `translate(${this.computedSize / 2}, ${this.computedSize / 2})`)
        .attr('role', 'img')
        .attr('aria-label', this.generateDescription())

      // Add central hole
      rings.append('circle').attr('r', centerHoleRadius).attr('fill', 'none')

      // Draw rings from outside in
      for (let i = this.chartData.length - 1; i >= 0; i--) {
        const item = this.chartData[i]
        const index = this.chartData.length - 1 - i

        const arc = {
          inner: centerHoleRadius + index * (ringWidth + ringGap),
          outer: centerHoleRadius + (index + 1) * (ringWidth + ringGap) - ringGap,
          start: 0,
          end: 2 * Math.PI,
        }

        const color = colors[i]
        const group = rings.append('g')

        // Draw background ring
        this.drawRing(group, { ...arc, start: 0, end: 2 * Math.PI }, color, 0.25, item)
        // Draw foreground ring (progress)
        this.drawRing(group, { ...arc, start: 0, end: 2 * Math.PI * item.perc }, color, 1, item)
        // Add text inside the ring
        this.addRingText(group, item, arc)
      }
    },
  },
}
</script>

<style scoped>
.multi-ring-chart {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 450px;
}

.multi-ring-chart svg {
  display: block;
  width: 100%;
  height: auto;
}

.tooltip {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  z-index: 1000;
  pointer-events: none;
  transition: opacity 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

@media (max-width: 767px) {
  .tooltip {
    font-size: 12px;
    padding: 6px 10px;
  }
}
</style>
